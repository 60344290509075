import React from 'react';

import {BrowserRouter, Switch, Route} from "react-router-dom";
import {Container, Row, Col, Image} from 'react-bootstrap';
import StartConsignment from './StartConsignment';
import ConsignItemForm from './ConsignItemForm';
import ConsignedItems from './ConsignedItems';
import ConsignerForm from './ConsignerForm';
import ConsignmentConfirmation from './ConsignmentConfirmation';
import ResumeConsignment from './ResumeConsignment';
import ConsignmentOver from './ConsignmentOver';
import './App.css';

class ConsignmentApp extends React.Component {
  constructor(props) {
    super(props);

    console.log(`Creating new instance of ConsignmentApp in ${process.env.REACT_APP_ENV} env`);

    this.state = {
      consignerInfo: {}
    }

    this.updateConsignerInfo = this.updateConsignerInfo.bind(this);
    this.getConsignerInfo = this.getConsignerInfo.bind(this);
    this.clearState = this.clearState.bind(this);
    this.getAuth = this.getAuth.bind(this);
    this.setAuth = this.setAuth.bind(this);
  }

  getAuth = () => {
    return JSON.parse(localStorage.getItem("auth"));
  }

  setAuth = (auth) => {
    localStorage.setItem("auth", JSON.stringify(auth));
  }

  updateConsignerInfo(consignerInfo) {
    localStorage.setItem("consignerInfo", JSON.stringify(consignerInfo));
  }

  getConsignerInfo() {
    return JSON.parse(localStorage.getItem("consignerInfo"));
  }

  clearState() {
    localStorage.clear();
  }

  render() {
    return (
      <BrowserRouter>
        <Container fluid>
          <Row className="header, justify-content-md-center">
            <Col sm={2}>
              <Image className="cochrans-logo" src="cochrans_symbol.png" fluid/>
            </Col>
            <Col sm={8}>
              <h1 className="cochrans-title">Cochran's Ski & Ride Sale</h1>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col sm={8}>
              <hr/>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col sm={8}>
              <Switch>
                <Route // exact path="/over" // comment out the exact path to close sale
                  render={
                    props => <ConsignmentOver  />
                  }
                />
                <Route exact path="/"
                  render={
                    props => <StartConsignment {...props}  updateConsignerInfo={this.updateConsignerInfo} />
                  }
                />

                 <Route exact path="/login"
                  render={
                    props => <ConsignerForm {...props} updateConsignerInfo={this.updateConsignerInfo} />
                  }
                />

                <Route exact path="/consignerinfo"
                  render={
                    props => <ConsignerForm {...props} updateConsignerInfo={this.updateConsignerInfo} setAuth={this.setAuth}/>
                  }
                />

                <Route exact path="/consignitem"
                  render={
                    props => <ConsignItemForm {...props} getAuth={this.getAuth}/>
                  }
                />

                <Route exact path="/consigneditems"
                  render={
                     props => <ConsignedItems {...props} getConsignerInfo={this.getConsignerInfo} setAuth={this.setAuth} getAuth={this.getAuth} updateConsignerInfo={this.updateConsignerInfo}/>
                  }
                />

                <Route exact path="/consignmentconfirmation"
                  render={
                     props => <ConsignmentConfirmation {...props} getConsignerInfo={this.getConsignerInfo} getAuth={this.getAuth} clearState={this.clearState}/>
                  }
                />

                <Route exact path="/resumeconsignment"
                  render={
                    props => <ResumeConsignment {...props}  updateConsignerInfo={this.updateConsignerInfo} clearState={this.clearState} setAuth={this.setAuth}/>
                  }
                />
              </Switch>
            </Col>
          </Row>
        </Container>
      </BrowserRouter>
    );
  }
}

export default ConsignmentApp;
