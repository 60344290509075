import React from "react";
import LoadingOverlay from 'react-loading-overlay';
import qs from 'qs';
import config from './config';

const apiBaseUrl = `${config.API_ENDPOINT}`;

class ResumeConsignment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    }

    this.updateConsignerInfo = props.updateConsignerInfo;
    this.setAuth = props.setAuth;
    this.clearState = props.clearState;
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });

    this.clearState();

    const querystring = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    console.log(`querystring: ${JSON.stringify(querystring)}`);

    const consignmentHash = querystring.consignmentId;
    if (! consignmentHash) {
      this.setState({
        loading: false,
        validConsignmentId: false
      })
    } else {
      const consignmentAuthUrl = `${apiBaseUrl}/consignments/${consignmentHash}`;
      const consignmentAuthResponse = await fetch(consignmentAuthUrl, {method: "HEAD"});

      if (consignmentAuthResponse.status != 200) {
        console.log(`Received status code ${consignmentAuthResponse.status} for consignment query.`);
        this.setState({
          ...this.state,
          validConsignmentId: false,
          loading: false
        })
      } else {
        this.setState({
          ...this.state,
          validConsignmentId: true
        })

        console.log(JSON.stringify(consignmentAuthResponse.headers));
        const token = consignmentAuthResponse.headers.get("Authorization").split(" ")[1];

        const base64Body = token.split('.')[1];
        const decodedBody = JSON.parse(window.atob(base64Body));

        const consignerId = decodedBody.sub;
        const consignerEmailAddress = decodedBody.emailAddress;

        const auth = {
          consignerId: consignerId,
          isVendor: decodedBody.isVendor,
          vendorId: decodedBody.vendorId,
          relaxValidation: decodedBody.relaxValidation,
          token: token
        };

        console.log(`auth: ${JSON.stringify(auth)}`);

        this.updateConsignerInfo({id: consignerId, emailAddress: consignerEmailAddress});
        this.setAuth(auth);

        this.props.history.push({pathname: '/consigneditems', state: {resumedConsignment: true}});
      }
    }
  }

  content() {

  }

  render() {
    let content = (<p>Searching for an existing consignment...</p>);

    if (!this.state.loading && !this.state.validConsignmentId) {
      content= (
        <p>Unable to find a matching consignment id. Please re-check your consignment link.</p>
      );
    }

    return (
      <LoadingOverlay
        active={this.state.loading}
        spinner
        text="Loading Consignment for {this.state.consigner.emailAddress}"
      >
        {content}
        <p/>
        <p>For support please email <a href="mailto:skisale@cochranskiclub.com">skisale@cochranskiclub.com</a></p>
      </LoadingOverlay>
    );
  }
}

export default ResumeConsignment;