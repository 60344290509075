import React from "react";
import {Form, Button, Alert} from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import config from './config';

class StartConsignment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailAddress: "",
      existingConsigner: null,
      resentConfirmationEmail: false
    }

    this.updateConsignerInfo = props.updateConsignerInfo;
    this.addItem = props.addItem;

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
    this.resendConfirmationEmail = this.resendConfirmationEmail.bind(this);
  }

  setLoading(state, text) {
    this.setState({
      ...this.state,
      loading: state,
      loadingText: text
    });
  }

  handleEmailChange(event) {
    this.setState({
        emailAddress: event.target.value
    });
  }

  async checkEmail() {
    this.setLoading(true, "Checking for existing consignment with this email address...");
    const consignersUrl = `${config.API_ENDPOINT}/consigners?emailAddress=${this.state.emailAddress}`;

    const consignersHeadResponse = await fetch(consignersUrl, {method: "HEAD"});

    this.setLoading(false, "");

    if (consignersHeadResponse.status !== 200) {
      console.log(`No consigner found for email address ${this.state.emailAddress}`);
      this.props.history.push({pathname: '/consignerinfo', state: {emailAddress: this.state.emailAddress}});
    } else {
      console.log(`consigner location: ${consignersHeadResponse.headers.get("Location")}`);

      this.setState({
        ...this.state,
        existingConsigner: consignersHeadResponse.headers.get("Location")
      })
    }
  }

  async resendConfirmationEmail() {
    this.setLoading(true, "Resending confirmation email...");
    const consignmentUpdateUrl = `${config.API_ENDPOINT}${this.state.existingConsigner}/consignmentUpdate`;
    const consignmentUpdatePostResponse = await fetch(consignmentUpdateUrl, {method: "POST"});
    this.setState({
      ...this.state,
      resentConfirmationEmail: true
    })
    this.setLoading(false);
  }

  render() {
    let existingConsignerContent = null;
    if (this.state.existingConsigner) {
      existingConsignerContent = (
        <div>
          <p>An existing consignment has been found for this email address (possibly from a previous year's sale). Please check your email for a confirmation email from
          Cochran's Ski Sale and use the included link to get back to your consignment.</p>
          <p>Please <a href="#" onClick={this.resendConfirmationEmail}>click here</a> to re-send your confirmation email.</p>
        </div>
      );
    }

    let resentConfirmationContent = null;
    if (this.state.resentConfirmationEmail) {
      existingConsignerContent = (
        <div>
          <p><em>Confirmation email re-sent, please check your email</em></p>
        </div>
      );
    }

    let flashMessage = null;
    if (this.props.location.state && this.props.location.state.messageType) {
      flashMessage = (<Alert variant={this.props.location.state.messageType}>
        {this.props.location.state.messageText}
      </Alert>);
    }

    return (
      <LoadingOverlay
        active={this.state.loading}
        spinner
        text={this.state.loadingText}
      >
        {flashMessage}
        <h3>Welcome to the 2024 Cochran's Ski Sale Consignment!</h3>
        <p>If you're looking to consign some of your ski and ride gear at this year's sale, you're in the right place!
        Please take a few minutes to read our <a href="./cochranskisale-consignment-tips.pdf" target="_blank">Consignment Guide</a> before
        getting started. Once you're ready, you can start your consignment by entering your email address.</p>

        <Form>
          <Form.Group controlId="email">
            <Form.Label>Email Address</Form.Label>
            <Form.Control type="email" onChange={this.handleEmailChange}/>
          </Form.Group>
        </Form>

        <Button onClick={this.checkEmail}>Next</Button>

        <p/>
        {existingConsignerContent}
        <p/>
        <p>
        {resentConfirmationContent}
        </p>
        <p>For support please email <a href="mailto:skisale@cochranskiclub.com">skisale@cochranskiclub.com</a></p>
      </LoadingOverlay>
    );
  }
}

export default StartConsignment;