import React from 'react';
import { Form, Button, ButtonToolbar, ButtonGroup, InputGroup } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { Formik } from 'formik';
import * as Yup from 'yup';
import LoadingOverlay from 'react-loading-overlay';

import ItemMetadata from './itemmetadata';
import config from './config';

const apiBaseUrl = `${config.API_ENDPOINT}`;

class ConsignItemForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      loading: false,
      item: props.location.state && props.location.state.item ? props.location.state.item : null
    }

    this.addItem = props.addItem;
    this.updateItem = props.updateItem;
    this.getAuth = props.getAuth;
    this.imageSupport = config.IMAGE_UPLOAD_SUPPORT

    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.addImages = this.addImages.bind(this);
    this.getSizeGroup = this.getSizeGroup.bind(this);
    this.getThumbs = this.getThumbs.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
  }

  setLoading(state, text) {
    this.setState({
      ...this.state,
      loading: state,
      loadingText: text
    });
  }

  static readFileContents(file) {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };

      temporaryFileReader.readAsArrayBuffer(file);
    });
  };

  async addImages(setFieldValue, files) {
    this.setLoading(true, "Uploading images...");
    console.log(`images to upload: ${JSON.stringify(files)}`);

    await Promise.all(files.map(async(file) => {
      const imageUploadUrl = `${apiBaseUrl}/imageupload`;
      const signedUrlRequestBody = {
        imagefilename: file.path
      };

      const signedUrlResponse = await fetch(imageUploadUrl, { method: "POST", body: JSON.stringify(signedUrlRequestBody) });

      const imageData = await signedUrlResponse.json();

      const fileContents = await ConsignItemForm.readFileContents(file);
      const uploadResponse = await fetch(imageData.signedUrl, {
        method: 'PUT',
        body: fileContents,
        headers: {
          'Content-Type': 'image/*',
        }
      });

      if (uploadResponse.ok) {
        console.log(`Completed upload for file ${file.path}`);

        let images = this.state.images;
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          uploadPath: imageData.imagePath
        })

        images.push(file);

        this.setState({
          images: images
        });

        setFieldValue("imageCount", images.length);
      }
      else {
        const uploadResponseBody = await uploadResponse.text();
        console.log(`Error uploading file to S3: ${uploadResponseBody}`)
        return;
      }
    }));

    this.setLoading(false, "");

    console.log(JSON.stringify(this.state));
  }

  deleteImage(setFieldValue, imageName) {
    console.log(`Deleting image ${imageName}`);
    const images = this.state.images;

    const index = images.findIndex(image => {
      return image.name === imageName
    });

    if (index < 0) {
      console.error(`unable to find image with name ${imageName} for delete`);
      return;
    }

    images.splice(index, 1);

    this.setState({
      ...this.state,
      images: images
    });

    setFieldValue("imageCount", images.length);

  }

  async submit(values) {
    this.setLoading(true, "Saving item...");
    const auth = this.getAuth();

    const subCategory =
      ItemMetadata[values.category].subCategory && ItemMetadata[values.category].subCategory.options.includes(values.subCategory)
        ? values.subCategory
        : "";

    const images = this.state.images.map((image) => image.uploadPath);

    const item = {
      consignerId: auth.consignerId,
      title: values.title,
      description: values.description,
      category: values.category,
      subCategory: subCategory,
      condition: values.condition,
      size: ItemMetadata[values.category].sizeField === "none" ? "N/A" : values.size,
      price: values.price,
      inventory: values.inventory,
      images: images,
      primaryImage: images[0],
      idOverride: values.idOverride,
      discountEligible: values.discountEligible,
      donateProceeds: values.donateProceeds
    }

    if (this.state.item) {
      item.id = this.state.item.id;
      const itemsUrl = `${apiBaseUrl}/items/${item.id}`;
      console.log(`Updating item: ${JSON.stringify(item)}`);
      const fetchOptions = {
        method: "PATCH",
        headers: {
            'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify(item)
      }
      const response = await fetch(itemsUrl, fetchOptions);
      console.log(`Updated item. Response is ${JSON.stringify(response)}`);
    } else {
      const itemsUrl = `${apiBaseUrl}/items`;
    	console.log(`Creating item: ${JSON.stringify(item)}`);
      const fetchOptions = {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${this.getAuth().token}`
        },
        body: JSON.stringify(item)
      }
      const response = await fetch(itemsUrl, fetchOptions);
      console.log(`Created item. Response is ${JSON.stringify(response)}`);
    }

    this.setLoading(false, "");

    this.props.history.push('/consigneditems');
  }

  cancel() {
    this.props.history.push('/consigneditems');
  }

  getSizeGroup(values, errors, handleChange, touched) {
    const metadata = ItemMetadata[values.category] ? ItemMetadata[values.category] : ItemMetadata["Default"];

    if (metadata.sizeField === "none") {
      return ("");
    }

    if (metadata.sizeField === "select") {
      let sizeOptions = metadata.sizeOptions.map((value, index) => {
        return (<option key={index}>{value}</option>)
      });
    }

    let formControl = (
      <Form.Control
        type="text"
        name="size"
        value={values.size}
        onChange={handleChange}
        isValid={touched.size && !errors.size}
        isInvalid={!!errors.size}
      />
    )

    if (metadata.sizeField === "select") {
      let sizeOptions = metadata.sizeOptions.map((value, index) => {
        return (<option key={index}>{value}</option>)
      });

      formControl = (
        <Form.Control
          as="select"
          name="size"
          value={values.size}
          onChange={handleChange}
          isValid={touched.size && !errors.size}
          isInvalid={!!errors.size}
        >
          <option value="">Please select...</option>
          {sizeOptions}
        </Form.Control>
      )
    }

    return (
      <Form.Group controlId="itemSize">
        <Form.Label>{metadata.sizeLabel}</Form.Label>
        {formControl}
        <Form.Control.Feedback type="invalid">
          {errors.size}
        </Form.Control.Feedback>
      </Form.Group>
    )
  }

  getThumbs(values, errors, handleChange, touched, setFieldValue) {
    const component = this;
    const thumbs = this.state.images.map((image, index) => {
      console.log(`adding image ${image.preview} to thumbnails`);
      const thumbHighlight = index === 0 ? "thumb-highlight" : ""
      return (
        <div className={`thumb ${thumbHighlight}`} key={image.name}>
          <div className="thumb-inner">
            <img
              src={image.preview}
              className="item-image"
            />
            <div className="thumb-overlay">
              <span className="thumb-text"><a href="#" onClick={component.deleteImage.bind(this, setFieldValue, image.name)} className="thumb-text">Delete</a></span>
            </div>
          </div>
        </div>
      )
    });

    return thumbs;
  }

  componentDidMount() {
    const component = this;
    const images = [];
    if (this.state.item) {
      this.state.item.images.forEach(image => {
        const imagePath = image.substring(image.indexOf(".com/") + 5)
        const isPrimary = component.state.item.primaryImage === imagePath;
        const imageObject = {
          name: imagePath,
          preview: image,
          uploadPath: imagePath
        };

        if (isPrimary) {
          images.unshift(imageObject);
        } else {
          images.push(imageObject);
        }
      });

      this.setState({
        ...this.state,
        images: images
      })
    }
  }

  render() {
    const component = this;

    const categoryNames = Object.keys(ItemMetadata).map((key) => {
      return key;
    });

    const categoryOptions = categoryNames.filter((name) => {return name !== "Default"}).map((category) => {
      return (<option key={category}>{category}</option>)
    });

    const getInitialValues = () => {
      let initialValues = {
        idOverride: "",
        title:"",
        description: "",
        category: "Default",
        subCategory: "",
        condition: "Default",
        size: "",
        price: "",
        inventory: 1,
        discountEligible: false,
        donateProceeds: false,
        imageCount: 0
      }

      if (component.state.item) {
        initialValues = component.state.item;
        initialValues.imageCount = component.state.item.images.length;
      }

      return initialValues;
    }

    const getItemDescriptionGroup = (values, errors, handleChange, touched) => {
        if (true) {
            return ("")
        } else {
            return (
                <Form.Group controlId="itemDescription">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea" rows="4"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    isValid={touched.description && !errors.description}
                    isInvalid={!!errors.description}
                  />
                  <Form.Text className="text-muted">
                    Be sure to include information about the size and condition of the item. For skis, please include condition of bases and edges. Maximum 1024 characters.
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
            );
        }
    }

    const getIdOverrideGroup = (values, errors, handleChange, touched) => {
      if (!component.getAuth().isVendor) {
        return ("");
      } else {
        return (
          <Form.Group controlId="itemIdOverride">
            <Form.Label>Item ID</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>{component.getAuth().vendorId}</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                name="idOverride"
                value={values.idOverride}
                onChange={handleChange}
                isValid={touched.idOverride && !errors.idOverride}
                isInvalid={!!errors.idOverride}
              />
              <Form.Control.Feedback type="invalid">
                {errors.idOverride}
              </Form.Control.Feedback>
            </InputGroup>
            <Form.Text className="text-muted">
              A unique numeric identifier for this item, between 1 and 999
            </Form.Text>
          </Form.Group>
        );
      }
    }

    const getInventoryGroup = (values, errors, handleChange, touched) => {
      if (!component.getAuth().isVendor) {
        return ("");
      } else {
        return (
          <Form.Group controlId="itemInventory">
            <Form.Label>Inventory (Count)</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                name="inventory"
                value={values.inventory}
                onChange={handleChange}
                isValid={touched.inventory && !errors.inventory}
                isInvalid={!!errors.inventory}
              />
              <Form.Control.Feedback type="invalid">
                {errors.inventory}
              </Form.Control.Feedback>
            </InputGroup>
            <Form.Text className="text-muted">
              Number of items that you are selling
            </Form.Text>
          </Form.Group>
        );
      }
    }

    const getImagesGroup = (values, errors, handleChange, touched, setFieldValue) => {
      if (!component.imageSupport) {
        return ("");
      } else {
        return (
          <Form.Group controlId="images">
              <Dropzone onDrop={this.addImages.bind(this, setFieldValue)} accept="image/*">
                {({getRootProps, getInputProps}) => (
                  <section>
                    <div {...getRootProps({className: "dropzone"})}>
                      <input {...getInputProps()} />
                      <p>Drag and drop your images here, or click to select files</p>
                    </div>
                    <aside className="thumb-container">
                      {this.getThumbs(values, errors, handleChange, touched, setFieldValue)}
                    </aside>
                  </section>
                )}
              </Dropzone>
              <Form.Control
                type="hidden"
                name="imageCount"
                value={values.imageCount}
                onChange={handleChange}
                isValid={touched.imageCount && !errors.imageCount}
                isInvalid={!!errors.imageCount}
              />
              <Form.Text className="text-muted">
                Please include at least 1 and ideally several images that show the conditions of the items.<br/>
                See our consignment guide for tips on taking pictures of your items<br/>
                The highlighted image will be the primary image on the online sale site.
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.imageCount}
              </Form.Control.Feedback>
            </Form.Group>
        );
      }
    }

    const getSubCategoryGroup = (values, errors, handleChange, touched, setFieldValue) => {
      const categoryMetadata = ItemMetadata[values.category];
      if (categoryMetadata.subCategory) {
        return (
          <Form.Group controlId="subCategory">
            <Form.Label>{categoryMetadata.subCategory.label}</Form.Label>
            <Form.Control
              required
              as="select"
              name="subCategory"
              value={values.subCategory}
              onChange={handleChange}
              isValid={touched.subCategory && !errors.subCategory}
              isInvalid={!!errors.subCategory}
            >
              <option value="">Please select...</option>
              {categoryMetadata.subCategory.options.map((option) => {
                return (<option>{option}</option>)
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.subCategory}
            </Form.Control.Feedback>
          </Form.Group>
        )
      } else {
        return "";
      }
    }

    const titleText = this.state.item ? "Edit Item" : "Consign an Item";

    return (
      <LoadingOverlay
        active={this.state.loading}
        spinner
        text={this.state.loadingText}
      >
        <h3>{titleText}</h3>
        <p>Please refer to our <a href="./cochranskisale-consignment-tips.pdf" target="_blank">Consignment Guide</a> for more information and tips on providing the right information and images for your consigned items.
        Also, please note that we are <em>not accepting helmets for consignment</em>.</p>
        <Formik
          validationSchema={Yup.object().shape({
            idOverride: Yup.mixed()
              .test("valid_id_override", "Please enter a number between 1 and 999", (value) => {
                if (!component.getAuth().isVendor) {
                  return true;
                } else if (!isNaN(value)) {
                  const numericValue = Number(value);
                  return Number.isInteger(numericValue) && numericValue >=1 && numericValue <= 999;
                } else {
                  return false;
                }
              }),
            title: Yup.string()
              .required("Required"),
            description: Yup.string()
              //.required("Required")
              .max(1024, "Maximum length of string is 1024 characters"),
            condition: Yup.string()
              .required("Required")
              .oneOf(["New", "Excellent", "Good", "Well-used", "Final season"]),
            category: Yup.string()
              .required("Required")
              .oneOf(categoryNames.slice(1), "Please choose a category for this item"),
            subCategory: Yup.mixed()
              .when("category", (category, schema) => {
                if (ItemMetadata[category].subCategory) {
                  return schema.required().oneOf(ItemMetadata[category].subCategory.options);
                } else {
                  return schema;
                }
              })
              .label("Type"),
            size: Yup.mixed()
              .when("category", {
                is: (category) => {
                  return ItemMetadata[category].sizeField !== "none"
                },
                then: Yup.mixed().required("Required")
              })
              .when("category", {
                is: (category) => {
                  return ItemMetadata[category].sizeField === "text"
                },
                then: Yup.number()
                  .typeError("Please enter a valid length")
                  .min(50, "Please enter a length of at least ${min}")
                  .max(250, "Please enter a length no greater than ${max}"),
                otherwise: Yup.string()
              }),
            price:Yup.number()
              .integer("Please enter a whole-number price")
              .typeError("Please enter a valid, whole-number price, without a dollar sign")
              .required("Required")
              .min(10, "Please enter a price of at least $${min}")
              .max(1000, "Please enter a price of less than $${max}"),
            inventory: Yup.number().integer().min(1).max(100),
            imageCount: Yup.number()
              .when((category, schema) => {
                return !component.imageSupport || component.getAuth().relaxValidation ? schema : schema.min(1, "Please upload at least one image");
              })
          })}
          onSubmit={this.submit}
          initialValues={getInitialValues()}
        >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            {getIdOverrideGroup(values, errors, handleChange, touched)}
            <Form.Group controlId="itemTitle">
              <Form.Label>Item Name</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={values.title}
                onChange={handleChange}
                isValid={touched.title && !errors.title}
                isInvalid={!!errors.title}
              />
              <Form.Text className="text-muted">
                Include make, model, and size, e.g. "Lange FreeRide - Mondo 28" or "Volkl Mantra - 184cm"
              </Form.Text>
            </Form.Group>

            {getItemDescriptionGroup(values, errors, handleChange, touched)}

            <Form.Group controlId="itemCondition">
              <Form.Label>Condition</Form.Label>
              <Form.Control
                as="select"
                name="condition"
                value={values.condition}
                onChange={handleChange}
                isValid={touched.condition && !errors.condition}
                isInvalid={!!errors.condition}
              >
                <option value="Default">Please select...</option>
                <option>New</option>
                <option>Excellent</option>
                <option>Good</option>
                <option>Well-used</option>
                <option>Final season</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.condition}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="itemCategory">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                name="category"
                value={values.category}
                onChange={handleChange}
                isValid={touched.category && !errors.category}
                isInvalid={!!errors.category}
              >
                <option value="Default">Please select...</option>
                {categoryOptions}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.category}
              </Form.Control.Feedback>
            </Form.Group>

            {getSubCategoryGroup(values, errors, handleChange, touched)}

            {this.getSizeGroup(values, errors, handleChange, touched)}

            <Form.Group controlId="itemPrice">
              <Form.Label>Price</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="price"
                  value={values.price}
                  onChange={handleChange}
                  isValid={touched.price && !errors.price}
                  isInvalid={!!errors.price}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.price}
                </Form.Control.Feedback>
              </InputGroup>
              <Form.Text className="text-muted">
                Price must be a whole-number, between $10 and $999
              </Form.Text>
            </Form.Group>

            {getInventoryGroup(values, errors, handleChange, touched)}

            {getImagesGroup(values, errors, handleChange, touched, setFieldValue)}

            <Form.Group controlId="discountEligible">
              <Form.Check
                type="checkbox"
                label="Sunday Special (item will be 20% off on last day of sale)"
                name="discountEligible"
                checked={values.discountEligible}
                onChange={handleChange}
                isValid={touched.discountEligible && !errors.discountEligible}
                isInvalid={!!errors.discountEligible}
              />
            </Form.Group>

            <Form.Group controlId="donateProceeds">
              <Form.Check
                type="checkbox"
                label="Donation (full proceeds of this item's sale will be donated to Cochran's Ski Club)"
                name="donateProceeds"
                checked={values.donateProceeds}
                onChange={handleChange}
                isValid={touched.donateProceeds && !errors.donateProceeds}
                isInvalid={!!errors.donateProceeds}
              />
            </Form.Group>

            <div>
              <Button type="submit" className="mr-2">Save Item</Button>
              <Button onClick={this.cancel} variant="warning">Cancel</Button>
            </div>

            <p/>
            <p>For support please email <a href="mailto:skisale@cochranskiclub.com">skisale@cochranskiclub.com</a></p>

          </Form>
        )}
        </Formik>
      </LoadingOverlay>
    )
  }
}

export default ConsignItemForm;





