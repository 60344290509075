import React from "react";
import {Form, Button, Alert} from 'react-bootstrap';
import {Formik} from 'formik';
import * as Yup from 'yup';
import LoadingOverlay from 'react-loading-overlay';
import config from './config';

const apiBaseUrl = `${config.API_ENDPOINT}`;

class ConsignerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingText: "",
      consignerExists: false
    }

    this.updateConsignerInfo = props.updateConsignerInfo;
    this.setAuth = props.setAuth;
    this.submit = this.submit.bind(this);
    this.consignerExists = this.consignerExists.bind(this);
    this.resendConfirmationEmail = this.resendConfirmationEmail.bind(this);
  }

  setLoading(state, text) {
    this.setState({
      ...this.state,
      loading: state,
      loadingText: text
    });
  }

  async consignerExists(emailAddress) {
    const consignersUrl = `${apiBaseUrl}/consigners?emailAddress=${emailAddress}`;

    const consignersHeadResponse = await fetch(consignersUrl, {method: "HEAD"});
    console.log(`consigner existence check returned status ${consignersHeadResponse.status}`)

    if (consignersHeadResponse.status === 200) {
      this.setState({
        ...this.state,
        consignerExists: true,
        existingConsigner: consignersHeadResponse.headers.get("Location"),
        existingConsignerEmail: emailAddress
      })

      return true;
    } else {
      this.setState({
        ...this.state,
        consignerExists: false,
        existingConsigner: null,
        existingConsignerEmail: null
      })

      return false
    }
  }

  async resendConfirmationEmail() {
    this.setLoading(true, "Resending confirmation email...");
    const consignmentUpdateUrl = `${apiBaseUrl}${this.state.existingConsigner}/consignmentUpdate`;
    const consignmentUpdatePostResponse = await fetch(consignmentUpdateUrl, {method: "POST"});
    this.setState({
      ...this.state,
      resentConfirmationEmail: true
    })
    this.setLoading(false);
  }

  async submit(values) {
     this.setState({
      ...this.state,
      loading: true,
      loadingText: "Saving consigner profile..."
    });

    if (await this.consignerExists(values.emailAddress)) {
      this.setState({
        ...this.state,
        loading: false,
        loadingText: ""
      })

      return;
    } else {
      this.setState({
        ...this.state,
        duplicateEmailAddress: false,
        emailAddress: null
      })
    }

    const consigner = {
      emailAddress: values.emailAddress,
      emailContactAgreement: values.emailContactAgreement,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      mailingAddress: values.mailingAddress,
      city: values.city,
      state: values.state,
      zipcode: values.zipcode
    }

    const consignersUrl = `${apiBaseUrl}/consigners`;
    const consignerPostResponse = await fetch(consignersUrl, {method: "POST", body: JSON.stringify(consigner)});
    const consignerPostData = await consignerPostResponse.json();

    consigner.id = consignerPostData.consignerId;
    consigner.consignmentHash = consignerPostData.consignmentHash;

    console.log(`Created consigner with id ${consigner.id}`);

    this.updateConsignerInfo({
      ...consigner
    });

    const consignmentsUrl = `${apiBaseUrl}/consignments/${consigner.consignmentHash}`;
    const consignmentAuthResponse = await fetch(consignmentsUrl, {method: "HEAD"});

    const token = consignmentAuthResponse.headers.get("Authorization").split(" ")[1];

    const base64Body = token.split('.')[1];
    const decodedBody = JSON.parse(window.atob(base64Body));

    const auth = {
      consignerId: decodedBody.sub,
      isVendor: decodedBody.isVendor,
      vendorId: decodedBody.vendorId,
      token: token
    };

    this.setAuth(auth);

    this.setState({
      ...this.state,
      loading: false,
      loadingText: ""
    });

    this.props.history.push({pathname: '/consignitem'});
  }

  render() {
    let duplicateEmailAddressAlert = null;
    if (this.state.consignerExists) {
      duplicateEmailAddressAlert = (
        <Alert variant="danger">
          The email address {} is already in use. Please check your email for a link to access your consignment,
          or <a href="#" onClick={this.resendConfirmationEmail}>click here</a> to re-send the link.
        </Alert>
      );
    }

    let resentConfirmationEmailAlert = null;
    if (this.state.resentConfirmationEmail) {
      resentConfirmationEmailAlert = (
        <Alert variant="success">
          Sent confirmation email with consignment link to {this.state.existingConsignerEmail}
        </Alert>
      );
    }

    return (
      <LoadingOverlay
        active={this.state.loading}
        spinner
        text={this.state.loadingText}
      >
        {duplicateEmailAddressAlert}
        {resentConfirmationEmailAlert}
        <h3>Consigner Information</h3>
        <p>To start the consignment process, please enter your personal information and confirm you have read
        the <a href="./cochranskisale-consignment-agreement.pdf " target="_blank">consignment agreement.</a></p>
        <Formik
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .required("Required"),
            lastName: Yup.string()
              .required("Required"),
            emailAddress: Yup.string()
              .email("Invalid email address")
              .required("Required"),
            confirmEmailAddress: Yup.string()
              .required()
              .test('email-addresses-match', 'Email addresses must match', function(value) {
                return this.parent.emailAddress === value;
              }),
            phoneNumber: Yup.string()
              .required("Required"),
            mailingAddress: Yup.string()
              .required("Required"),
            city: Yup.string()
              .required("Required"),
            state: Yup.string()
              .required("Required"),
            zipcode: Yup.string()
              .required("Required")
              .min(5, "Zipcode must be 5 digits")
              .max(5, "Zipcode must be 5 digits"),
            consignmentAgreementAttestation: Yup.boolean()
              .oneOf([true], "Required")
          })}
          onSubmit={this.submit}
          initialValues={{
            firstName:"",
            lastName: "",
            emailAddress: this.props.location.state ? this.props.location.state.emailAddress : "",
            confirmEmailAddress: "",
            emailContactAgreement: false,
            phoneNumber: "",
            mailingAddress: "",
            city: "",
            state: "VT",
            zipcode: "",
            consignmentAgreementAttestation: false
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="emailAddress">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  name="emailAddress"
                  value={values.emailAddress}
                  onChange={handleChange}
                  isValid={touched.emailAddress && !errors.emailAddress}
                  isInvalid={!!errors.emailAddress}
                />
                <Form.Text className="text-muted">
                  Your email address will not be shared beyond the Cochran's Ski Sale team
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.emailAddress}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="confirmEmailAddress">
                <Form.Label>Confirm Email Address</Form.Label>
                <Form.Control
                  type="email"
                  name="confirmEmailAddress"
                  value={values.confirmEmailAddress}
                  onChange={handleChange}
                  isValid={touched.confirmEmailAddress && !errors.confirmEmailAddress}
                  isInvalid={!!errors.confirmEmailAddress}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmEmailAddress}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  isValid={touched.firstName && !errors.firstName}
                  isInvalid={!!errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  isValid={touched.lastName && !errors.lastName}
                  isInvalid={!!errors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="phoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  isValid={touched.phoneNumber && !errors.phoneNumber}
                  isInvalid={!!errors.phoneNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="mailingAddress">
                <Form.Label>Mailing Address</Form.Label>
                <Form.Control
                  type="text"
                  name="mailingAddress"
                  value={values.mailingAddress}
                  onChange={handleChange}
                  isValid={touched.mailingAddress && !errors.mailingAddress}
                  isInvalid={!!errors.mailingAddress}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.mailingAddress}
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                  Address where you would like payment for sold items to be mailed
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  isValid={touched.city && !errors.city}
                  isInvalid={!!errors.city}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control
                  as="select"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  custom
                >
                  <option value="AK">Alaska</option>
            			<option value="AL">Alabama</option>
            			<option value="AR">Arkansas</option>
            			<option value="AZ">Arizona</option>
            			<option value="CA">California</option>
            			<option value="CO">Colorado</option>
            			<option value="CT">Connecticut</option>
            			<option value="DC">District of Columbia</option>
            			<option value="DE">Delaware</option>
            			<option value="FL">Florida</option>
            			<option value="GA">Georgia</option>
            			<option value="HI">Hawaii</option>
            			<option value="IA">Iowa</option>
            			<option value="ID">Idaho</option>
            			<option value="IL">Illinois</option>
            			<option value="IN">Indiana</option>
            			<option value="KS">Kansas</option>
            			<option value="KY">Kentucky</option>
            			<option value="LA">Louisiana</option>
            			<option value="MA">Massachusetts</option>
            			<option value="MD">Maryland</option>
            			<option value="ME">Maine</option>
            			<option value="MI">Michigan</option>
            			<option value="MN">Minnesota</option>
            			<option value="MO">Missouri</option>
            			<option value="MS">Mississippi</option>
            			<option value="MT">Montana</option>
            			<option value="NC">North Carolina</option>
            			<option value="ND">North Dakota</option>
            			<option value="NE">Nebraska</option>
            			<option value="NH">New Hampshire</option>
            			<option value="NJ">New Jersey</option>
            			<option value="NM">New Mexico</option>
            			<option value="NV">Nevada</option>
            			<option value="NY">New York</option>
            			<option value="OH">Ohio</option>
            			<option value="OK">Oklahoma</option>
            			<option value="OR">Oregon</option>
            			<option value="PA">Pennsylvania</option>
            			<option value="PR">Puerto Rico</option>
            			<option value="RI">Rhode Island</option>
            			<option value="SC">South Carolina</option>
            			<option value="SD">South Dakota</option>
            			<option value="TN">Tennessee</option>
            			<option value="TX">Texas</option>
            			<option value="UT">Utah</option>
            			<option value="VA">Virginia</option>
            			<option value="VT">Vermont</option>
            			<option value="WA">Washington</option>
            			<option value="WI">Wisconsin</option>
            			<option value="WV">West Virginia</option>
            			<option value="WY">Wyoming</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="zipcode">
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  type="text"
                  name="zipcode"
                  value={values.zipcode}
                  onChange={handleChange}
                  isValid={touched.zipcode && !errors.zipcode}
                  isInvalid={!!errors.zipcode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.zipcode}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="consignmentAgreementAttestation">
                <Form.Check
                  type="checkbox"
                  label={<div><span>I have read and agree to the</span> <a href="./cochranskisale-consignment-agreement.pdf " target="_blank">Cochran's Ski Sale Consignment Agreement</a></div>}
                  name="consignmentAgreementAttestation"
                  value={values.consignmentAgreementAttestation}
                  onChange={handleChange}
                  isValid={touched.consignmentAgreementAttestation && !errors.consignmentAgreementAttestation}
                  isInvalid={!!errors.consignmentAgreementAttestation}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.consignmentAgreementAttestation}
                </Form.Control.Feedback>
              </Form.Group>
              <p><em>Please confirm all information is correct before moving to the next step.</em></p>
              <Button type="submit">Next: Consign Items</Button>
            </Form>
          )}
        </Formik>
      </LoadingOverlay>
    );
  }
}

export default ConsignerForm;