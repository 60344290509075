import React from 'react';
import {Table, Button, ButtonToolbar} from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import config from './config';

const apiBaseUrl = `${config.API_ENDPOINT}`;
const sessionExpirationMessage = "Your session has expired, please check your email for a link to resume your consignment";

class ConsignedItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingText: "",
      items: []
    }

    if (this.props.location.state && this.props.location.state.resumedConsignment) {
      this.state.resumedConsignment = true;
    }

    this.getAuth = props.getAuth;
    this.setAuth = props.setAuth;
    this.updateConsignerInfo = props.updateConsignerInfo;

    this.completeConsignment = this.completeConsignment.bind(this);
    this.consignItem = this.consignItem.bind(this);
    this.editItem = this.editItem.bind(this);
    this.setLoading = this.setLoading.bind(this);

    this.deleteItem = this.deleteItem.bind(this);
    this.loadItems = this.loadItems.bind(this);
  }

  setLoading(state, text) {
    this.setState({
      ...this.state,
      loading: state,
      loadingText: text
    });
  }

  consignItem() {
    if (!this.getAuth()) {
      this.props.history.push({pathname: '/', state: {messageType: "warning", messageText: sessionExpirationMessage}});
      return;
    }

    this.props.history.push({pathname: '/consignitem'});
  }

  editItem(item) {
    if (!this.getAuth()) {
      this.props.history.push({pathname: '/', state: {messageType: "warning", messageText: sessionExpirationMessage}});
      return;
    }

    this.props.history.push({pathname: '/consignitem', state: {item: item}});
  }

  async deleteItem(item) {
    if (!this.getAuth()) {
      this.props.history.push({pathname: '/', state: {messageType: "warning", messageText: sessionExpirationMessage}});
      return;
    }

    this.setLoading(true, "Deleting item...");
  	const itemsUrl = `${apiBaseUrl}/items/${item.id}`;
  	console.log(`Deleting item: ${JSON.stringify(item)}`);
    const fetchOptions = {
      method: "DELETE",
      headers: {
          'Authorization': `Bearer ${this.getAuth().token}`
      }
    }
    const response = await fetch(itemsUrl, fetchOptions);
    console.log(`Deleted item. Response is ${JSON.stringify(response)}`);
    this.setLoading(false, "");

    this.loadItems();
  }

  async completeConsignment() {
    this.setLoading(true, "Completing Consignment...");

    const consigner = this.props.getConsignerInfo();

    console.log("Updating consignment:");
    const consignmentUpdateUrl = `${apiBaseUrl}/consigners/${consigner.id}/consignmentUpdate`;
    const consignmentUpdatePostResponse = await fetch(consignmentUpdateUrl, {method: "POST"});
    console.log(`Received status code ${consignmentUpdatePostResponse.status} from consignmentUpdate`);

    this.setLoading(false, "");

    this.props.history.push({pathname: '/consignmentconfirmation'});
  }

  async loadItems() {
    this.setLoading(true, "Loading consigned items...");
    const consigner = this.props.getConsignerInfo();

    const fetchOptions = {
      method: "GET",
      headers: {
          'Authorization': `Bearer ${this.getAuth().token}`
      }
    }

    const itemsUrl = `${apiBaseUrl}/items?consignerId=${consigner.id}`;
    console.log(`Retrieving items for consignerId ${consigner.id}`);

    const itemsResponse = await fetch(itemsUrl, fetchOptions);
    const items = await itemsResponse.json();
    console.log(`Retrieved items: ${JSON.stringify(items)}`);

    this.setState({
      ...this.state,
      items: items ? items : [],
    });

    this.setLoading(false, "");
  }

  async componentDidMount() {
    if (!this.getAuth()) {
      this.props.history.push({pathname: '/', state: {messageType: "warning", messageText: sessionExpirationMessage}});
      return;
    }

    this.loadItems();
  }

  render() {
    const consigner = this.props.getConsignerInfo();
    let welcomeMessage = null;

    if (this.state.resumedConsignment) {
      welcomeMessage = (
        <div>
          <p>Welcome back {consigner.emailAddress}, we have located your existing consignment information. If this is not you, please
           <a href="./"> click here</a> to start or resume your consignment.</p>
        </div>
      );
    }

    const component = this;

    const itemRows = [];

    const currentItems = this.state.items;

    currentItems.forEach(function(item, index) {
      itemRows.push((
        <tr key={index}>
          <td>{item.idOverride ? component.getAuth().vendorId.concat(item.idOverride) : (item.id ? item.id : "NEW")}</td>
          <td>{item.title}</td>
          <td>{item.category}</td>
          <td>{item.condition}</td>
          <td>{item.size}</td>
          <td>{item.price}</td>
          <td>{item.discountEligible ? "Yes" : "No"}</td>
          <td>{item.donateProceeds ? "Yes" : "No"}</td>
          <td>
            <ButtonToolbar>
              <Button size="sm" className="mr-1" variant="danger" onClick={component.deleteItem.bind(component,item)}>Delete</Button>
              <Button size="sm" onClick={component.editItem.bind(component,item)}>Edit</Button>
            </ButtonToolbar>
          </td>
        </tr>
      ));
    });
    return(
      <LoadingOverlay
        active={this.state.loading}
        spinner
        text={this.state.loadingText}
      >
        <h3>Consigned Items</h3>
        {welcomeMessage}
        <p>Please continue to add items to your consignement and click "Complete Consignment" when done</p>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Item ID</th>
              <th>Item Name</th>
              <th>Category</th>
              <th>Condition</th>
              <th>Size</th>
              <th>Price</th>
              <th>Sunday Special</th>
              <th>Donation</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {itemRows}
          </tbody>
        </Table>

        <ButtonToolbar>
          <Button className="pull-left mr-2" onClick={this.consignItem}>Add Another Item</Button>
          <Button className="pull-right" variant="success" onClick={this.completeConsignment}>Complete Consignment</Button>
        </ButtonToolbar>

        <p/>
        <p>For support please email <a href="mailto:skisale@cochranskiclub.com">skisale@cochranskiclub.com</a></p>

      </LoadingOverlay>
    )
  }
}

export default ConsignedItems;

