const dev = {
  API_ENDPOINT: "https://l60xqidpg2.execute-api.us-east-1.amazonaws.com"
};

const prod = {
  API_ENDPOINT: "https://szyw89yk8e.execute-api.us-east-1.amazonaws.com"
};

const config = process.env.REACT_APP_ENV === 'prod'
  ? prod
  : dev;

export default {
  IMAGE_UPLOAD_SUPPORT: false,
  ...config
};